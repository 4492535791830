import { ReactComponent as GoBackIcon } from 'images/icons/new_back_icon.svg';
import IconButton from 'common/components/buttons/IconButton';
import { StyledGoBack } from './style';

function GoBack({text, onClick, show }) {
  if (!show) return null;
  return (
    <StyledGoBack className="go-back" onClick={onClick} data-testid="go-back-button">
      <IconButton
        className="back-icon-button"
        icon={<GoBackIcon />}
        btnTheme="extraSmallIconButton"
        onClick={onClick}
      />
      <span>{text}</span>
    </StyledGoBack>
  );
}
export default GoBack;
