import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGoBack = styled(Box)(() => ({
  padding: '10px 10px 20px 10px',
  cursor: 'pointer',
  width: 'fit-content',
  'button.back-icon-button': {
    minWidth: 'auto',
    marginRight: '10px',
  },
  '&> span': {
    fontSize: '12px',
  },
}));
