import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { details } from 'store/userSlice';

/**
 * ---P.O.C---  DriftChat component for integrating Drift customer chat widget
 * This component doesn't render anything visible, only loads and configures Drift
 */

const DRIFT_WIDGET_ID = '9kp3wtnsrs3k';

const DriftChat = React.memo (() => {
  const userDetails = useSelector(details);
  const url = window.location.href;

  useEffect(() => {
    if (window.drift && window.drift.init) return;
    
    const initDrift = () => {
      if (!window.driftt) {
        window.driftt = [];
      }
      window.drift = window.driftt;
      const drift = window.drift;

      if (!drift.init) {
        if (drift.invoked) {
          console.error("Drift snippet included twice.");
          return;
        }

        drift.invoked = true;
        drift.methods = [
          "identify", "config", "track", "reset", "debug",
          "show", "ping", "page", "hide", "off", "on"
        ];

        drift.factory = function createMethod(e) {
          return function executeMethod(...args) {
            args.unshift(e);
            drift.push(args);
            return drift;
          };
        };

        drift.methods.forEach(function registerMethod(e) {
          drift[e] = drift.factory(e);
        });

        drift.load = function loadScript(t) {
          const e = 3e5;
          const n = Math.ceil(new Date() / e) * e;
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.crossOrigin = "anonymous";
          script.src = `https://js.driftt.com/include/${n}/${t}.js`;

          const firstScript = document.getElementsByTagName("script")[0];
          if (firstScript && firstScript.parentNode) {
            firstScript.parentNode.insertBefore(script, firstScript);
          } else {
            document.head.appendChild(script);
          }
        };
      }
    };

    initDrift();
    window.drift.SNIPPET_VERSION = '0.3.1';
    window.drift.load(DRIFT_WIDGET_ID);

    const configureDrift = () => {
      if (!window.drift) return;

      window.drift.on('ready', function handleDriftReady(api) {
        api.sidebar.open();
        api.widget.show();
        if (!api) return;
        const userAttributes = {
          Country: userDetails?.timezone || 'Unknown',
          URL: url
        };

        api?.setUserAttributes(userAttributes);


        console.log('Drift chat widget initialized successfully');
      });
    };

    configureDrift();
  }, [userDetails, url]);

  return null;
});

export default DriftChat;